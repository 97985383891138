<template>
  <div>
    <AppHeader title="技能管理"/>
    <Panel class="tabs search-panel" :title="false">
      <div slot="content">
        <div class="line">
          <div class="flex-dir-row mt10">
            <div class="flex-item-auto">
              <input class="line-input" id="keyword" v-model="keyword" placeholder="姓名/学工号"/>
            </div>
            <div class="btn btn-small green ml5" @click="reset">重设</div>
            <div class="btn btn-small green ml5" @click="initUsers">查询</div>
          </div>
        </div>
      </div>
    </Panel>
    <Panel class="tabs mt15" :title="false">
      <div slot="content" class="status-content" v-infinite-scroll="loadUsers"
           infinite-scroll-disabled="loading"
           infinite-scroll-distance="10">
        <div class="line" v-for="(user,idx) of users" style="position: relative;overflow: hidden">
          <div :class="`member-status ${user.type===0?'external':'inner'}`">{{ user.type === 0 ? '外' : '内' }}</div>
          <div class="flex-dir-row">
            <div class="line-label">用户：</div>
            {{ user.realName }}
          </div>
          <div class="flex-dir-row">
            <div class="line-label">学号：</div>
            {{ user.no }}
          </div>
          <div class="flex-dir-row">
            <div class="flex-dir-row flex-item-auto">
              <div class="line-label">技能值：</div>
              {{ user.point }}
            </div>
            <div class="flex-dir-row">
              <div class="btn btn-xsmall green" @click="showAdjustDialog(user)">调整</div>
            </div>
          </div>
        </div>
        <div class="line text-center" v-if="users.length===0">暂无记录</div>
      </div>
    </Panel>

    <mt-popup
        v-model="adjustVisible"
        position="bottom"
        v-if="adjustVisible"
    >
      <div class="popup-title">技能值调整</div>
      <div style="border-bottom: 1px solid #ddd; padding: 20px">
        <div class="flex-dir-row">
          <div class="popup-form-label">调整账号</div>
          {{ curUser.realName }}
        </div>
        <div class="flex-dir-row mt10">
          <div class="popup-form-label">当前技能值</div>
          {{ curUser.point }}
        </div>
        <div class="flex-dir-row mt10">
          <div class="popup-form-label">增加值</div>
          <input class="line-input flex-item-auto" placeholder="填写一个整数" v-model="pointAddTest"/>
        </div>
        <div class="flex-dir-row mt10">
          <div class="popup-form-label">调整后值</div>
          {{ pointAfter }}
        </div>
        <div class="flex-dir-row mt10">
          <div class="popup-form-label"><span style="color: red">*</span>备注</div>
          <textarea class="flex-item-auto" rows="10" placeholder="备注" v-model="remark"></textarea>
        </div>
      </div>
      <div class="mint-msgbox-btns">
        <button class="mint-msgbox-btn mint-msgbox-cancel" @click="adjustVisible=false">取消</button>
        <button class="mint-msgbox-btn mint-msgbox-confirm" @click="doAdjust">确定</button>
      </div>
    </mt-popup>

  </div>
</template>

<style lang="scss">
.member-status {
  left: 0;
  top: 0;
  position: absolute;
  font-size: 10px;
  text-align: center;
  color: #fff;
  padding: 0 10px;
  opacity: .9;
  width: 50px;
  height: 20px;
  line-height: 20px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);

  &.external {
    background: orange;
  }

  &.inner {
    background: #3b5998;
  }
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {InfiniteScroll, DatetimePicker, Picker, MessageBox, Toast} from 'mint-ui';
import {mapState} from 'vuex'

export default {
  components: {
    AppHeader,
    Panel,
    InfiniteScroll,
    DatetimePicker,
    Picker
  },
  data() {
    return {
      page: 1,
      users: [],
      loading: false,
      noMore: false,
      adjustVisible: false,
      keyword: '',
      curUser: null,
      pointAddTest: null,
      remark: null
    }
  },
  computed: {
    pointAdd() {
      if (this.pointAddTest) {
        let ret = parseInt(this.pointAddTest)
        if (isNaN(ret)) {
          return 0
        }
        return ret
      }
      return 0
    },
    pointAfter() {
      if (this.curUser) {
        return this.curUser.point + this.pointAdd
      }
      return this.curUser.point
    }
  },
  methods: {
    reset() {
      this.page = 1
      this.loading = false
      this.keyword = ''
    },
    resetAdjust() {
      this.remark = null
      this.pointAddTest = null
      this.curUser = null
    },
    dateFormat(timestamp) {
      if (timestamp) {
        return this.$moment(timestamp).format('YYYY-MM-DD HH:mm')
      }
      return ''
    },
    initUsers() {
      this.users = []
      this.page = 1
      this.noMore = false
      this.loadUsers()
    },
    loadUsers() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      // data.append('biz', 2)
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      this.keyword && data.append('keyword', `${this.keyword}`)
      that.$http.post('/portal/point/queryPoint', data).then(res => {
        if (res.data && res.data.length > 0) {
          that.users = that.users.concat(res.data)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    },
    preventDefault(e) {
      e.preventDefault()
    },
    showAdjustDialog(user) {
      this.curUser = user
      this.adjustVisible = true
    },
    doAdjust() {
      if (this.pointAdd === 0) {
        Toast("请输入有效的调整值")
        return
      }
      this.$http.post('/portal/point/doAdjust', {
        userId: this.curUser.id,
        point: this.pointAdd,
        remark: this.remark
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
        if (res.success) {
          this.adjustVisible = false
          Toast("调整成功！")
          this.initUsers()
        }
      })
    }
  },
  mounted() {
    this.initUsers()
  },
  watch: {
    adjustVisible: {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.resetAdjust()
        }
      }
    }
  }
}
</script>
